<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill="#FDEAE8" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21 20C21 18.8954 21.8954 18 23 18H31C32.1046 18 33 18.8954 33 20V25C33 26.1046 32.1046 27 31 27H23C21.8954 27 21 26.1046 21 25V20ZM23 21C23 20.4477 23.4477 20 24 20H30C30.5523 20 31 20.4477 31 21C31 21.5523 30.5523 22 30 22H24C23.4477 22 23 21.5523 23 21ZM24 23C23.4477 23 23 23.4477 23 24C23 24.5523 23.4477 25 24 25H30C30.5523 25 31 24.5523 31 24C31 23.4477 30.5523 23 30 23H24Z"
      fill="#B82025"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 15H25C26.1046 15 27 15.8954 27 17H23C21.3431 17 20 18.3431 20 20V25C20 26.6569 21.3431 28 23 28H27V31C27 32.1046 26.1046 33 25 33H17C15.8954 33 15 32.1046 15 31V17C15 15.8954 15.8954 15 17 15ZM19 30C19 29.4477 19.4477 29 20 29H22C22.5523 29 23 29.4477 23 30C23 30.5523 22.5523 31 22 31H20C19.4477 31 19 30.5523 19 30Z"
      fill="#B82025"
    />
  </svg>
</template>
